/**
 *
 * FAQ Item (Question and Answer)
 *
 *
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Plus, { Minus } from 'components/icons/Plus';

const FAQItem = ({ item: { question, answer } }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <Item onClick={toggle}>
      <div>
        <Question className="question">{question}</Question>
        <Answer
          isOpen={isOpen}
          dangerouslySetInnerHTML={{ __html: answer }}
        ></Answer>
      </div>

      <div>
        <SVGWrapper>
          {isOpen ? <Minus width="35px" /> : <Plus width="35px" />}
        </SVGWrapper>
      </div>
    </Item>
  );
};

FAQItem.propTypes = {
  item: PropTypes.shape({
    question: PropTypes.any.isRequired,
    answer: PropTypes.any.isRequired,
  }),
};

export default FAQItem;

const Item = styled.li`
  ${({ theme }) => theme.styles.boxShadows['1']};
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  margin-top: 30px;
  border-radius: 3px;
  transition: box-shadow 0.3s;
  cursor: pointer;

  &:hover {
    ${({ theme }) => theme.styles.boxShadows['2']};
  }

  > div {
    padding: 25px;
  }
`;

const Question = styled.h3`
  font-size: 1.2rem;
  line-height: 1.8rem;
  transition: color 0.3s;
`;

const Answer = styled.p`
  font-size: 1rem;
  margin-top: 20px;
  a {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.Primary};
    text-decoration: underline;
  }
  ${({ theme, isOpen }) =>
    theme.styles.showhideStyles(isOpen, 0.3, 'color 0.3s')};
`;

const SVGWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 22px;

  svg {
    transition: fill 0.3s;
    fill: ${({ theme }) => theme.colors.midGreen};
  }
`;
