/**
 *
 * Vimeo
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const VideoEmbed = ({ vimeoUrl, background, ...props }) => (
  <EmbedContainer background={background} {...props}>
    {
      vimeoUrl ? (
        <iframe
          src={vimeoUrl}
          title="vimeo Ripspot promo"
          width="640"
          height="360"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        />
      ) : (
        <video 
          allowFullScreen 
          autoPlay={true} 
          poster={'/images/site-bg.jpg'}
          loop={true}
          controls={false}
          muted={true}
        >
          <source src="https://ripspot-production.s3.amazonaws.com/marketing/Homepage+Drone+Video.mp4" type="video/mp4" />
        </video>
      )
    }
  </EmbedContainer>
);

VideoEmbed.propTypes = {
  vimeoUrl: PropTypes.string,
};

export default VideoEmbed;

const EmbedContainer = styled.div`
  width: 100%;
  height: 100%;
  ${({ background }) =>
    background &&
    css`
      position: absolute;
      top: 0px;
      left: 0px;
      overflow: hidden;
    `}
  video {
    height: 100%;
    width: 100%;
    position: absolute;
    object-fit: cover;
    top: 0px;
    left: 0px;
  }
  iframe {
    width: 100%;
    height: 100%;
  }
`;
