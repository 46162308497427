/**
 *
 * Plus
 *
 *
 */

import React from 'react';
import { SVGPropTypes } from 'utils/propTypes';

const Plus = ({ fill, width = '100%', height = '100%', style = {} }) => (
  <svg style={style} width={width} height={height} viewBox="0 0 48 48">
    <rect fill={fill} x="22" y="8" width="4" height="32" />
    <rect
      fill={fill}
      transform="translate(24.000000, 24.000000) rotate(90.000000) translate(-24.000000, -24.000000) "
      x="22"
      y="8"
      width="4"
      height="32"
    />
  </svg>
);
export const PlusThick = ({
  fill,
  width = '100%',
  height = '100%',
  style = {},
}) => (
  <svg
    style={style}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    version="1.1"
    viewBox="0 0 564 564"
    x="0px"
    y="0px"
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <g>
      <polygon
        fill={fill}
        points="227,0 337,0 337,227 564,227 564,337 337,337 337,564 227,564 227,337 0,337 0,227 227,227 "
      />
    </g>
  </svg>
);

export const Minus = ({
  fill,
  width = '100%',
  height = '100%',
  style = {},
}) => (
  <svg style={style} width={width} height={height} viewBox="0 0 48 48">
    {/* <rect fill={fill} x="22" y="8" width="4" height="32" /> */}
    <rect
      fill={fill}
      transform="translate(24.000000, 24.000000) rotate(90.000000) translate(-24.000000, -24.000000) "
      x="22"
      y="8"
      width="4"
      height="32"
    />
  </svg>
);

export const PlusCirlce = ({
  fill = '#000',
  width = '100%',
  height = '100%',
  style = {},
}) => (
  <svg style={style} width={width} height={height} viewBox="0 0 24 24">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <circle stroke={fill} cx="12" cy="12" r="9.5" />
      <path d="M7.25,12 L16.75,12" stroke={fill} strokeLinecap="square" />
      <path d="M12,16.75 L12,7.25" stroke={fill} strokeLinecap="square" />
    </g>
  </svg>
);

Plus.propTypes = SVGPropTypes;
PlusThick.propTypes = SVGPropTypes;
Minus.propTypes = SVGPropTypes;
PlusCirlce.propTypes = SVGPropTypes;

export default Plus;
