/**
 *
 * FAQ Page
 *
 *
 */

import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import Vimeo from 'components/lib/Vimeo';

import SEO from 'components/seo';
import FAQPage from 'containers/FAQPage';
import theme from 'utils/theme';

const Help = ({ location }) => {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <SEO title="Help" />
        <h1>Helpful Tips</h1>
        <Vimeo
          vimeoUrl="https://player.vimeo.com/video/520043851?title=0&byline=0&portrait=0"
          className="video-container"
        />
        <p style={{margin: '2em 0'}}>This video will show you how to create a Ripspot.</p>
        <div className="text-container">
          <h2>Other Tips:</h2>
          <p>
            While you can always add photos to a gallery later, it will be
            helpful to gather them into one folder, before you begin creating a
            Ripspot. This will make it easier to find and upload them.
          </p>
          <p>
            It can be helpful to write the biography ahead of time, cutting and
            pasting it into the biography section of Ripspot when you're ready.
          </p>
          <p>
            It can take time to gather photos and relevant information. As you create a Ripspot, your edits and content are auto-saved, allowing you to finish at a later time, before sharing it with friends and family. You’ll always be able to edit the Ripspot, adding photos as you come across them.
          </p>
          <p>
            If you'd like to invite other people to help add photos, pins or
            contribute to the biography, you can share administrative
            capabilities with them. To do so, click the "admin" button
            associated with the Ripspot in your account settings. All you will
            need is their email address, however they will need to have their
            own Ripspot account. Ask them to create their own account first.
          </p>
          <p>
            You can also transfer an account to someone else, giving them
            complete ownership of a Ripspot. To do this, choose "admin" under
            account settings, then the "transfer" option.
          </p>
          <p>
            If you have questions or suggestions, feel free to let us know by
            sending an email to:
            <a href="mailto:support@ripspot.com">support@ripspot.com</a>
          </p>
        </div>
        <FAQPage location={location} limit={false} />
      </Container>
    </ThemeProvider>
  );
};

Help.propTypes = {
  location: PropTypes.any,
};

export default Help;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding-top: 3em;
  h1 {
    margin: 2em 0;
  }
  .video-container {
    max-width: 1024px;
    position: relative;
    padding: 0px;
    &:after {
      content: '';
      display: block;
      padding-bottom: 56.25%;
    }
    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }
  .text-container {
    max-width: 700px;
    margin: 4em 0;
    > p {
      margin: 1em 0;
    }
    p {
      a {
        color: ${({ theme }) => theme.colors.Primary};
        text-decoration: underline;
      }
    }
  }
`;
